@import "../../styles/variables.scss";

#demo-simple-select {
  color: $text-light !important;
  p {
    color: $text-light;
    font-family: Mulish;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
  em {
    color: $text-light;
    font-family: Mulish;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
  }
}

#menuItem {
  transition: 0.5s ease;

  em {
    color: $text-light;

    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    opacity: 0.7;
    transition: 0.5s ease;
  }

  &:hover {
    background: rgba(24, 24, 24, 0.1);
    transition: 0.5s ease;
    em {
      opacity: 1;
      transition: 0.5s ease;
    }
  }
}
.MuiList-root {
  width: 300px !important;
  background-color: #1852e4;
}
.MuiMenu-paper {
  width: 300px !important;
  min-width: 0 !important;
  background-color: #1852e4 !important;
}

.MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}
