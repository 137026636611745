@import "styles/variables.scss";

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background-color: $projects_text;
  color: #fff;
  @include ibm500;
  font-size: 22px;
  padding: 55px 0px 88px;
  line-height: 28px;
  @include mobile {
    padding: 43px 0px 79px;
    font-size: 18px;
  }
  & a {
    width: fit-content;
  }
}

.wr {
  display: grid;
  grid-template-areas: "email links" "phone links" "address label";
  grid-template-areas: ". links" "email links" "phone label" "address label";
  grid-template-columns: 2fr 1fr;
  gap: 43px;
  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;
  }
}

.links {
  grid-area: links;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-self: end;
  align-self: start;
  @include mobile {
    gap: 14px;
    padding: 16px 0;
  }
  & > a {
    width: 53px;
    height: 53px;
    transition: transform 0.3s;
    &:hover {
      transform: scale(1.1);
    }
    @include mobile {
      width: 40px;
      height: 40px;
    }
    & svg {
      width: 100%;
      height: 100%;
    }
  }
}
.email {
  grid-area: email;
  @include hoveredText;
  &:hover {
    background-size: 100% 1.5px;
  }
}
.phone {
  grid-area: phone;
  @include hoveredText;
  &:hover {
    background-size: 100% 1.5px;
  }
}
.label {
  justify-self: end;
  align-self: end;
  grid-area: label;
  @include mulish;
  font-size: 18px;
  line-height: normal;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  @include mobile {
    font-size: 15px;
    max-width: 100%;
    align-self: start;
    align-items: flex-start;
  }
  & > span {
    display: block;
  }
}
.address {
  grid-area: address;
  & > p {
    @include hoveredText;
    width: fit-content;
  }
  &:hover {
    & > p {
      background-size: 100% 1.5px;
    }
  }
}

//--------- MEDIA ---------

@media (max-width: 770px) {
  .wrapper {
    padding: 43px 0px 63px 15px;
    height: auto;
    max-height: calc(100vh - 306px);
  }

  .social {
    flex-direction: column;
    .links {
      margin-bottom: 37px;
      width: 147px;
      & img {
        width: 40px;
        height: 40px;
      }
    }
    .contacts {
      margin-bottom: 62px;
      .email {
        font-size: 27px;
        margin-bottom: 17px;
      }
      .phone {
        font-size: 18px;
      }
    }
  }

  .info {
    align-items: flex-start;
    flex-direction: column;

    .label {
      font-size: 15px;
      line-height: 18px;
    }
    .address {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 37px;
    }
  }
}
