@import "../../../styles/variables.scss";

.block {
  margin-bottom: 96px;
}
.Mark3 {
  h1, h2, h3, h4, h5, h6 {
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 31px;
    margin-bottom: 27px;
    @include laptop {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  p {
    font-family: Mulish;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    position: relative;
    @include laptop {
      font-size: 20px;
    }

    @include mobile {
      font-size: 20px;
    }
  }

  ul {
    width: 70%;
    @include laptop {
      width: 100%;
    }

    li {
      margin-bottom: 5px;
      font-family: Mulish;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 31px;
      position: relative;
      padding-left: 32px;
      @include laptop {
        font-size: 20px;
      }
      @include mobile {
        margin-bottom: 10px;
        word-wrap: break-word;
        font-size: 22px;
      }

      &::before {
        content: "";
        position: absolute;
        display: block;
        border-radius: 100px;
        background-color: #0F4CEAFF;
        width: 20px;
        height: 20px;
        top: 5px;
        left: 0;
        @include mobile {
          width: 15px;
          height: 15px;
          top: 10px;
        }
      }
    }
  }
}