@import "styles/variables.scss";

.wr {
  margin-bottom: 100px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px 60px;
  grid-auto-rows: 580px;
  padding-bottom: 203px;
  @include laptop {
    grid-auto-rows: 336px;
    gap: 28px 30px;
    padding-bottom: 103px;
  }
  @include mobile {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 35px;
    padding-bottom: 53px;
  }
}

.card {
  @include ibm;
  text-transform: uppercase;
  &:nth-child(2n-1) {
    margin-top: 203px;
    @include laptop {
      margin-top: 110px;
    }
    @include mobile {
      margin-top: 0px;
    }
  }
  &_image__wr {
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 15px;
    aspect-ratio: 3 / 2;

    @include laptop {
      margin-bottom: 7px;
    }
    & > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      transition: transform 0.3s;
    }
  }
  &_tags {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    margin-bottom: 15px;
    @include laptop {
      gap: 7px;
      margin-bottom: 7px;
    }
    & > li {
      border-radius: 24px;
      border: 1px solid $header;
      padding: 2px 18px;
      color: $header;
      font-size: 20px;
      text-transform: uppercase;
      @include laptop {
        border-radius: 14px;
        padding: 1px 10px;
        font-size: 12px;
      }
    }
  }
  &_title > h2 {
    font-weight: 400;
    color: $projects_text;
    font-size: 44px;
    display: inline;
    @include hoveredText;
    @include laptop {
      font-size: 24px;
    }
  }

  &:hover {
    & img {
      transform: scale(1.05);
    }
    & h2 {
      background-size: 100% 1.5px;
    }
  }
}

.filter {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 32px;
  @include laptop {
    margin-bottom: 17px;
    gap: 7px;
  }
  & button {
    border: 1px solid transparent;

    min-width: auto;
    padding: 0 20px;
    border-radius: 100px;
    background-color: #f8f8f8;
    color: #a3a3a3;
    @include ibm500;
    font-size: 20px;
    line-height: 52px;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
    @include laptop {
      padding: 0 10px;
      font-size: 12px;
      line-height: 27px;
    }
    &:hover {
      background-color: #868686;
      color: #ffffff;
      @include mobile {
        background-color: #f8f8f8;
        color: #a3a3a3;
      }
    }
    &:active,
    &:focus {
      opacity: 1;
    }
  }
  & .filter_button__active {
    background-color: #000 !important;
    color: #fff !important;
  }
}
